import './App.css';
import {BrowserRouter as Browser, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Aprofesionales from './pages/solutions/Aprofesionales';
import Consorcios from './pages/solutions/Consorcios'; 
import Cooperativas from './pages/solutions/Cooperativas';
import Ecommerce from './pages/solutions/Ecommerce';
import Educacion from './pages/solutions/Educacion';
import Empresas from './pages/solutions/Empresas';
import Gobierno from './pages/solutions/Gobierno';
import Suscripciones from './pages/solutions/Suscripciones';
import Tickets from './pages/solutions/Tickets';
import TerminosCondiciones from './pages/TerminosCondiciones';
import Integrate from './pages/Integrate';
import NotFound from './pages/NotFound';
import Head from './components/all/Head';
import Foot from './components/all/Foot';
import Inicio from './pages/Inicio';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CodigoBarras from './pages/services/CodigoBarras';
import LinkPagos from './pages/services/LinkPagos';
import OrdenesPagos from './pages/services/OrdenesPagos';
import PagosQr from './pages/services/PagosQr';
import SitioWeb from './pages/services/SitioWeb';
import Totems from './pages/services/Totems';
import TerminosCondicionesPdf from './pages/TerminosCondicionesPdf';
import PreguntasFrecuentes from './pages/PreguntasFrecuentes';
import Reclamos from './pages/Reclamos';
import Contracargos from './pages/Contracargos';
import Respuesta from './pages/Respuesta';
import SeguimientoReclamos from './pages/SeguimientoReclamos';
import React, { useEffect, useState } from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import icon from './components/img/iconpayfun.png';
import whatsapp from './uploads/whatsapp.txt'
import PagoSeguro from './pages/PagoSeguro';
import Disputas from './pages/disputas/Disputas';
import Formulario from './pages/disputas/Formulario.js';
import LandingPage from './pages/LandingPage';
import RespuestaLandingPage from './pages/RespuestaLandingPage';
import TerminosCondicionesUsuarioFinal from './pages/TerminosCondicionesUsuarioFinal.js';
import TerminosCondicionesUsuariosFinalesPdf from './pages/TerminosCondicionesUsuariosFinalesPdf.js';
import FormIntegrate from './pages/Integrate/FormIntegrate.js';
import FormError from './pages/Integrate/FormError.js';



AOS.init({once: true});

function App() {
  const [phone,setPhone] = useState("");

  useEffect (()=>{
    fetch(whatsapp)
    .then(r => r.text())
    .then(text => { setPhone(text) });
  })

  return (
    <>
    <Browser>
    <Head/>
    <div className='containerFluid base' style={{marginTop:'80px'}}>
      
      <Routes>
        <Route path='/' element={<Inicio/>}/>
        <Route path='/pagos-exitosos-y-seguros' element={<PagoSeguro/>}/>
        <Route path='/soluciones/asociaciones-profesionales' element={<Aprofesionales/>}/>
        <Route path='/soluciones/consorcios' element={<Consorcios/>}/>
        <Route path='/soluciones/cooperativas-de-servicios' element={<Cooperativas/>}/> 
        <Route path='/soluciones/ecommerce-y-marketplace' element={<Ecommerce/>}/> 
        <Route path='/soluciones/instituciones-educativas' element={<Educacion/>}/> 
        <Route path='/soluciones/empresas-y-comercios-fisicos' element={<Empresas/>}/>   
        <Route path='/soluciones/gobiernos-y-municipios' element={<Gobierno/>}/> 
        <Route path='/soluciones/suscripciones' element={<Suscripciones/>}/> 
        <Route path='/soluciones/venta-de-tickets-online-para-eventos' element={<Tickets/>}/> 

        <Route path='/servicios/lector-de-codigo-de-barras' element={<CodigoBarras/>}/>
        <Route path='/servicios/links-de-pago' element={<LinkPagos/>}/>
        <Route path='/servicios/ordenes-de-pago' element={<OrdenesPagos/>}/>
        <Route path='/servicios/pagos-con-qr' element={<PagosQr/>}/>
        <Route path='/servicios/pagos-en-tu-sitio-web' element={<SitioWeb/>}/>
        <Route path='/servicios/totems-interactivos' element={<Totems/>}/>

        <Route path='/legales/terminos-y-condiciones' element={<TerminosCondiciones/>}/>
        <Route path='/legales/terminos-y-condiciones-pdf' element={<TerminosCondicionesPdf/>}/>
        <Route path='/legales/terminos-y-condiciones-usuario-final' element={<TerminosCondicionesUsuarioFinal/>}/>
        <Route path='/legales/terminos-y-condiciones-usuarios-finales-pdf' element={<TerminosCondicionesUsuariosFinalesPdf/>}/>
        <Route path='/preguntas-frecuentes' element={<PreguntasFrecuentes/>}/>

        <Route path='/integrate-con-payfun' element={<Integrate/>}/>
        <Route path='/integrate-con-payfun/formulario' element={<FormIntegrate/>}/>
        <Route path='/integrate-con-payfun/error' element={<FormError/>}/>
        <Route path='/enviado/:codigo' element={<Respuesta/>}/>
        <Route path='/reclamos' element={<Reclamos/>}/>
        <Route path='/reclamos/contracargos' element={<Contracargos/>}/>
        {/* <Route path='/reclamos/formulario-de-reclamos' element={<FormReclamos/>}/> */}
        <Route path='/reclamos/seguimiento-de-reclamos' element={<SeguimientoReclamos/>}/>
        <Route path='/reclamos/centro-de-consultas-y-reclamos' element={<Disputas/>}/>
        <Route path='/reclamos/formulario/:subtema' element={<Formulario/>}/>
        <Route path='/potencia-tu-proceso-de-pago' element={<LandingPage/>}/>
        <Route path='/gracias' element={<RespuestaLandingPage/>}/>
        <Route path='/*' element={<NotFound/>}/>
      </Routes>
   
    </div>
    <Foot/>
    </Browser>
    <FloatingWhatsApp 
        phoneNumber={phone}
        accountName = "PayFun" 
        popupMessage = "Hola, en que podemos ayudarte?" 
        headerColor = "#FE3887" 
        headerTitle = "Payfun chat" 
        statusMessage = "Servicio de WhatsApp para clientes."
        chatMessage = "Para reclamos dirigirte a la sección reclamos, en la pestaña contactos."
        placeholder ="Escribe tu consulta"
        avatar={icon}
        allowEsc
        allowClickAway
        notification = "false"
        notificationSound="false"
    />
    </>
  );
}

export default App;
