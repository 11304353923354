import Error from '../../components/img/icons/icon-error.png';

export default function FormError(){
    return(
    <div className='notFound'  >
        <div style={{maxWidth:'600px'}}>
        <img width='130' height='130' src={Error} alt='enviado' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000"/>
        <h2 data-aos="fade-up" data-aos-offset="50" data-aos-delay="150" data-aos-duration="1000">Ups! Hubo un inconveniente.</h2>
        <p className='parrafo' data-aos="fade-up" data-aos-offset="250" data-aos-delay="50" data-aos-duration="1000">Le recordamos que nuestros servicios están orientados solo a empresas, entidades y profesionales con CUIT habilitado. Este formulario está pensado solo para que los interesados en ellos puedan solicitar información y no es un medio de consulta para usuarios finales.</p>
        </div>
    </div>
    ) 
}