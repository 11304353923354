import { useFormik } from 'formik';
import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";

const Integrate = () => {
  const [envio, setEnvio] = useState({});
  const [nocaptcha, setNocaptcha] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textButton = isSubmitting ? "Enviando..." : "Enviar";
  const captchaRef = useRef(null);

  const formik = useFormik({
    initialValues: { cuit: '' },
    validationSchema: Yup.object({
      cuit: Yup.string()
        .matches(/^\d+$/, "Solo se permiten números")
        .min(11, 'Se requiere un CUIT válido')
        .max(13, 'Se requiere un CUIT válido')
        .required('Campo requerido'),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setNocaptcha(''); // Resetea mensajes de error previos

      try {
        const captchaToken = captchaRef.current.getValue();
        if (!captchaToken) {
          setNocaptcha('Por favor tilde la casilla de verificación para procesar el pedido');
          setIsSubmitting(false);
          return;
        }

        const tokenResponse = await fetch(process.env.REACT_APP_GOOGLE, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ token: captchaToken }),
        });
        const tokenData = await tokenResponse.json();

        if (tokenData.success) {
          const updatedEnvio = { ...envio, token: tokenData.message, ...values };
          setEnvio(updatedEnvio);

          const cuitResponse = await fetch(process.env.REACT_APP_CUIT, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify(updatedEnvio),
          });
          const cuitData = await cuitResponse.json();

          if (cuitData.success) {
            captchaRef.current.reset();
            localStorage.setItem('identity', JSON.stringify(cuitData.data));
            window.location = cuitData.data.CategoriaImpositiva !== "CF"
              ? `/integrate-con-payfun/formulario`
              : `/integrate-con-payfun/error`;
          } else {
            setNocaptcha(cuitData.error || 'Error al procesar la solicitud');
          }
        } else {
          setNocaptcha('Por favor tilde la casilla de verificación para procesar el pedido');
        }
      } catch (error) {
        console.error('Error:', error);
        setNocaptcha('Ocurrió un error inesperado. Por favor, inténtelo de nuevo más tarde.');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div id="integrate" className="containerFluid">
        <Container className="container-body-2">
          <Row>
            <Col md={12} className="mb-2 mt-5">
              <h2>Integrate con PayFun</h2>
            </Col>
            <Col md={12} className="mb-2 mt-5">
              <p>
                Consultá por una prueba gratis y todo tipo de consulta acerca de nuestro servicio. 
                Queremos conocer más sobre tu entidad o empresa. Primero verificaremos los datos de tu CUIT con ARCA.
              </p>
            </Col>
            <Col md={12} className="d-flex justify-content-center">
              <label className="form-label" htmlFor="cuit">
                Coloca tu Nº CUIT sin guiones (ej: 20413040931)
              </label>
            </Col>
            <Col md={12} className="d-flex justify-content-center">
              <input
                className="form-control"
                id="cuit"
                type="text"
                {...formik.getFieldProps('cuit')}
                style={{ maxWidth: '200px' }}
              />
            </Col>
            <Col md={12} className="d-flex justify-content-center">
              {formik.touched.cuit && formik.errors.cuit ? (
                <div className="errorMsj">{formik.errors.cuit}</div>
              ) : null}
            </Col>
            <Col md={12} className="d-flex justify-content-center mt-4">
              <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
            </Col>
            <Col md={12} className="d-flex justify-content-center mb-3">
              <p><strong>{nocaptcha}</strong></p>
            </Col>
            <Col md={12} className="d-flex justify-content-center">
              <button
                id="submit"
                className="btn btn-primary mt-4 mb-5"
                type="submit"
                disabled={isSubmitting}
              >
                {textButton}
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </form>
  );
};

export default Integrate;