import { useEffect, useState } from 'react';
import PdfViewer from '../components/all/PdfViewer';
import legales from '../uploads/legales_finales.txt'

export default function TerminosCondicionesUsuariosFinalesPdf(){

    const [texto,setTexto] = useState("");

    useEffect (()=>{
        fetch(legales)
        .then(r => r.text())
        .then(text => { setTexto(text) });
    })

    return(
        <div id='terminosPdf'>
            <PdfViewer texto={texto}  title={"Términos y Condiciones Para Usuarios Finales"}/>    
        </div>
    ) 
}